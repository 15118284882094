import styled from 'styled-components';

export const LogosCarousel = styled.div`
  text-align: center;
  width: 100vw;

  .slick-list {
    overflow: hidden;
    margin-top: -${({ theme }) => theme.spacing[16]};
    margin-bottom: -${({ theme }) => theme.spacing[16]};
  }

  .slick-track {
    display: flex;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing[16]};

    .slick-slide > div {
      height: 100%;
    }
  }
`;
