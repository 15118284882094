import styled from 'styled-components';

export const CompanyLogoWrapper = styled.a<{ noLink: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing[16]};
  margin: 0 ${({ theme }) => theme.spacing[8]};
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  width: 256px;
  height: 128px;

  :hover {
    opacity: 0.6;
  }
  
  ${({ noLink }) =>
    noLink &&
    `
    pointer-events: none;
    cursor: default;
  `}
`;

export const ImageWrapper = styled.div`
  height: 72px;
  width: 100%;
  position: relative;
  display: block;
`;
