import Slider from 'react-slick';
import { useResponsive, useWindowSize } from '@/hooks';
import { type ICompanyLogo, ResponsiveCollection } from '@/interfaces';
import CompanyLogo from '../CompanyLogo';
import { LogosCarousel } from './CompanyLogos.styles';

const LOGO_WIDTH = 256;

const CompanyLogos: React.FC<{ data: ICompanyLogo[] }> = ({ data }) => {
  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);
  const { width } = useWindowSize();
  const dataLength = data?.length || 0;
  const isInfinite = dataLength * LOGO_WIDTH > width;

  const customSettings = {
    infinite: isInfinite,
    arrows: false,
    slidesToShow: isInfinite ? (isMobile ? 1 : 3) : dataLength,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    variableWidth: true,
    initialSlide: 0,
    pauseOnHover: false,
  };

  return (
    <LogosCarousel>
      <Slider {...customSettings}>
        {data?.map(
          (item: ICompanyLogo, itemIndex) =>
            item?.logo && <CompanyLogo key={itemIndex} logo={item.logo} logoLink={item.logoLink} />,
        )}
      </Slider>
    </LogosCarousel>
  );
};

export default CompanyLogos;
