import Image from 'next/image';
import { useGlobalState } from '@/hooks';
import { CompanyLogoWrapper, ImageWrapper } from './CompanyLogo.styles';

interface CompanyLogoProps {
  logo: {
    contentType?: string;
    url: string;
    description: string;
  };
  logoLink?: string;
}

const CompanyLogo: React.FC<CompanyLogoProps> = ({ logo, logoLink }) => {
  const { getProperImageUrl } = useGlobalState();
  const logoUrl = getProperImageUrl(logo);

  return (
    <CompanyLogoWrapper href={logoLink} target='_blank' rel='noopener' noLink={!logoLink}>
      <ImageWrapper>
        <Image
          src={logoUrl}
          alt={logo.description}
          quality={100}
          fill
          sizes='100vw'
          style={{
            objectFit: 'contain',
          }}
        />
      </ImageWrapper>
    </CompanyLogoWrapper>
  );
};

export default CompanyLogo;
